<template>
  <div>
    <v-card elevation="1" outlined>
      <v-toolbar dense elevation="0">
        <v-toolbar-title title="Displays the List that you must Approve.">My Approval</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="py-1 my-0">
        <app-text-info>This tab serves as a control center where you can manage approval requests that require your action. It displays all pending requests, allowing you to view the status, submission details, and take action such as approving, rejecting, or providing comments on the requests. This feature helps you efficiently track and handle all approval processes, ensuring no requests are overlooked.</app-text-info>
      </v-card-text>

      <v-tabs v-model="tab">
        <v-tab><v-badge color="primary" v-if="totalNeedToApprove > 0" dot>Need to Approve</v-badge> <span v-else>Need to Approve</span></v-tab>
        <v-tab>Has been Approved</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <needto-approve @total-data="setTotalNeedToApprove"></needto-approve>
        </v-tab-item>
        <v-tab-item>
          <has-been-approved @total-data="setHasBeenApproved"></has-been-approved>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import HasBeenApproved from './ListDataHasBeenApproved.vue';
// import DetailDialog from "./DetailDialog.vue";
import NeedtoApprove from './ListDataNeedtoApprove.vue';
export default {
  components: {
    // DetailDialog, 
    NeedtoApprove,
    HasBeenApproved
  },
  data() {
    return {
      loading: null,
      search: "",
      results: [],
      tab: null,

      totalNeedToApprove: 0,
      totalHasBeenApproved: 0,
    };
  },

  methods: {
    setTotalNeedToApprove(val) {
      this.totalNeedToApprove = val;
    },
    setHasBeenApproved(val) {
      this.totalHasBeenApproved = val;
    },
  },

  mounted() {
  },
};
</script>
